import * as React from 'react';
import { createTheme } from '@mui/material/styles';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ChargingStationIcon from '@mui/icons-material/EvStation';
import DeviceThermostatIcon from '@mui/icons-material/DeviceThermostat';
import TimelineIcon from '@mui/icons-material/Timeline';
import PlaceIcon from '@mui/icons-material/Place';
import ListIcon from '@mui/icons-material/List';
import MapIcon from '@mui/icons-material/Map';
import { AppProvider, type Navigation } from '@toolpad/core/AppProvider';
import { DashboardLayout } from '@toolpad/core/DashboardLayout';
import { Outlet, useNavigate } from 'react-router-dom';

const NAVIGATION: Navigation = [
  {
    kind: 'header',
    title: 'Main items',
  },
  {
    segment: 'dashboard',
    title: 'Dashboard',
    icon: <DashboardIcon />,
  },
  {
    segment: 'charging',
    title: 'Charging',
    icon: <ChargingStationIcon/>,
  },
  {
    segment: 'location',
    title: 'Location',
    icon: <PlaceIcon />,
        children: [
      {
        segment: 'map',
        title: 'Map',
        icon: <MapIcon />,
      },
      {
        segment: 'trackers',
        title: 'Trackers',
        icon: <ListIcon />,
      },
    ],
  },

  {
    segment: 'environment',
    title: 'Environment',
    icon: <DeviceThermostatIcon />,
  },
  {
    kind: 'divider',
  },
  {
    kind: 'header',
    title: 'Facilities',
  },
  {
    segment: 'building',
    title: 'Building',
    icon: <TimelineIcon />,
  },
];

const demoTheme = createTheme({
  cssVariables: {
    colorSchemeSelector: 'data-toolpad-color-scheme',
  },
  colorSchemes: { light: true, dark: true },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 600,
      lg: 1200,
      xl: 1536,
    },
  },
});

function Toolbar() {
  const navigate = useNavigate();

  const handleNavigation = (segment: string) => {
    if (segment === 'dashboard') { 
      navigate(`/`);
    } else if (segment) {
      navigate(`/${segment}`);
    } else {
      navigate(`/`);
    }
  };

  return (
    <AppProvider
      navigation={NAVIGATION}
      onNavigate={handleNavigation}
      theme={demoTheme}
      branding={{
        logo: <img src="/logos/ODP_Logo.png" alt="MUI logo" />,
        title: 'ODP',
      }}
    >
      <DashboardLayout>
        <div style={{
          overflowY: 'auto',
          height: '100vh',
          display: 'flex',
          flexDirection: 'column',
        }}>
          <Outlet />
        </div>
      </DashboardLayout>
    </AppProvider>
  );
}

export default Toolbar;
