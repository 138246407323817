import React from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import DevicesGrid from './components/grids/devicesGrid';
import ChargingGrid from './components/grids/chargingGrid';
import ProtectedRoute from './components/protectedRoute';
import GolfMapHandler from './components/handlers/golfMap-handler';
import EnvironGrid from './components/grids/environmentalGrid';
import DashboardGrid from './components/grids/dashboardGrid';
import Toolbar from './components/navigation/toolbar';
import BuildingGrid from './components/grids/buildingGrid';

function App() {
  return (
    <Router>
      <Routes>
        <Route element={<Toolbar />}>
          <Route path="/" element={
            <ProtectedRoute>
              <DashboardGrid />
            </ProtectedRoute>
          } />
          <Route path="/dashboard" element={
            <ProtectedRoute>
              <DashboardGrid />
            </ProtectedRoute>
          } />
          <Route path="/map" element={
              <GolfMapHandler />
          } />
          <Route path="/location/map" element={
              <GolfMapHandler />
          } />
          <Route path="/:deviceID" element={
              <GolfMapHandler />
          } />
          <Route path="/location/trackers" element={
            <ProtectedRoute>
              <DevicesGrid />
            </ProtectedRoute>
          } />
          <Route path="/charging" element={
              <ChargingGrid />
          } />
          <Route path="/building" element={
            <ProtectedRoute>
              <BuildingGrid />
            </ProtectedRoute>
          } />
          <Route path="/environment" element={
            <EnvironGrid />
          } />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;

